import React from 'react'
import BlogsTabs from '../../component/blog/Blog'

const Blog = () => {
  return (
    <div style={{backgroundColor:"#F1F4FD"}}>
      <BlogsTabs/>  
    </div>
  )
}

export default Blog