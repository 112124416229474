import React from 'react'
import Company from '../../component/company/Company'

const MainCompany = () => {
  return (
    <div>
        <Company/>
    </div>
  )
}

export default MainCompany