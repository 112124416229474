
export const navItems = [

    {
        id: 1,
        title: "Industries",
        url: "/industries",
    },
    {
        id: 2,
        title: "Services",
        url: "/services",
    },
    {
        id: 3,
        title: "Expertise",
        url: "/expertise",
    },
    {
        id: 4,
        title: "Stories",
        url: "/project",
    },
    {
        id: 5,
        title: "Company",
        url: "/company",
    },
    {
        id: 6,
        title: "Blog(Coming Soon)",
        url: "/blog",
    }
]
