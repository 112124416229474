import React from 'react'
import ServicesDetail from '../../component/services/ServicesDetail'

function Detail() {
  return (
      <>
         <ServicesDetail/>
      </>
  )
}

export default Detail