import React from 'react'
import Project from '../../component/project/Project'

const MainProjec = () => {
  return (
    <div style={{backgroundColor:"#F1F4FD"}}>
      <Project/>  
    </div>
  )
}

export default MainProjec